<template>
    <div id="app">
        <!-- Dynamic Header Component -->
        <div class="main-layout">
            <!-- Side Header Component -->
            <app-nav
                v-if="getClientPortalUrl"
                :class="[
                    headerStore.header.routeName == 'Inbox' &&
                    ChatStore.toggle_chat
                        ? 'modile_view_hide'
                        : '',
                ]"
            />

          

            <!-- Router View -->
            <div
                class="app_container app_detail_container"
                :class="
                    getClientPortalUrl ? 'bg-gray-100 sm:pl-16' : '!bg-white'
                "
            > 
            <!-- <app-topbar
                v-if="getClientPortalUrl"
                :class="
                    boardStore.getCurrentBoard == 'Inbox' ? 'inbox-topbar' : ''
                "
            >
                <div
                    class="whatsapp-notification fixed top-0 bg-purple-500 text-sm font-normal leading-none text-black z-50"
                >
                    <div class="flex items-center justify-between w-full px-3">
                        <div class="flex gap-2 items-center">
                            <fa
                                class="text-white"
                                :icon="['far', 'triangle-exclamation']"
                            ></fa>
                            <p>Your account is not linked to WhatsApp</p>
                        </div>
                     
                        <a
                            href="#"
                            @click.prevent="triggerModal(modalVisible, 'QR')"
                            >Link to WhatsAppxxxxx ></a
                        >
                    </div>
                </div>
                <topbar-heading />

                <topbar-center>
               
                </topbar-center>
                <topbar-right>
                    <topbar-user
                        ref="topbar"
                        @openProfile="openEditProfileModal"
                        :userData="userData"
                    />
                </topbar-right>
            </app-topbar> -->
                <router-view @updateHeaderProps="updateHeaderProps" />
            </div>
        </div>
    </div>
    <Teleport to="body">
       
        <app-toast-container>
            <template v-for="(s, index) in toastsSuccess" :key="index">
                <app-toast v-if="s.show" :headingText="s.title" :subText="s.message" toastType="success"
                    :undoBtn="true" />
            </template>
            <template v-for="(w, index1) in toastsWarning" :key="index1">
                <app-toast v-if="w.show" :headingText="w.title" :subText="w.message" toastType="warning" />
            </template>
            <template v-for="(e, index2) in toastsError" :key="index2">
                <app-toast v-if="e.show" :headingText="e.title" :subText="e.message" toastType="error" />
            </template>
            <template v-for="(i, index3) in toastsInfo" :key="index3">
                <app-toast v-if="i.show" :headingText="i.title" :subText="i.message" toastType="info" />
            </template>
            <template v-for="(g, index4) in toastsGeneric" :key="index4">
                <app-toast v-if="g.show" :headingText="g.title" :subText="g.message" toastType="generic" />
            </template>
        </app-toast-container>
    </Teleport>
    <Teleport to="body">
        <spinner-modal
            modalShowVal="spinnerModalToShow"
            heading="Fees"
            ref="spinnerModal"
            :content="LoaderContent"
        />
    </Teleport>
</template>

<script setup>
import AppNav from '@/components/navigation/AppNav.vue';
import { onBeforeMount, onMounted, computed, ref, onUpdated, watch } from 'vue';

// Vue router imports
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useTenantStore } from '@/stores/tenant';
import { useHeaderStore } from '@/stores/headerStore.js';
// I18n imports
import { useI18n } from 'vue-i18n';

// Components imports
import AppTopbar from '@/components/topbar/AppTopbar.vue';
import TopbarHeading from '@/components/topbar/TopbarHeading.vue';
import TopbarRight from '@/components/topbar/TopbarRight.vue';
import TopbarLeft from '@/components/topbar/TopbarLeft.vue';
import TopbarSearch from '@/components/topbar/TopbarSearch.vue';
import TopbarNotification from '@/components/topbar/TopbarNotification.vue';
import TopbarUser from '@/components/topbar/TopbarUser.vue';
import AppOffcanvas from '@/components/useroffcanvas/AppOffcanvas.vue';
import AppOffcanvasBody from '@/components/useroffcanvas/AppOffcanvasBody.vue';
import AppOffcanvasClose from '@/components/useroffcanvas/AppOffcanvasClose.vue';
import AppOffcanvasHeader from '@/components/useroffcanvas/AppOffcanvasHeader.vue';
import AppOffcanvasAccounts from '@/components/useroffcanvas/AppOffcanvasAccounts.vue';
import AppOffcanvasList from '@/components/useroffcanvas/AppOffcanvasList.vue';
import AppOffcanvasBackdrop from '@/components/useroffcanvas/AppOffcanvasBackdrop.vue';
import AppSwitch from '@/components/forms/AppSwitch.vue';
import AppToastContainer from '@/components/toasts/AppToastContainer.vue';
import AppToast from '@/components/toasts/AppToast.vue';
import UserModal from '@/components/settings/modals/UserModal.vue';
import TopbarCenter from '@/components/topbar/TopbarCenter.vue';
import AppSmartSearch from '@/components/smartSearch/AppSmartSearch.vue';
import SpinnerModal from '@/components/modal/SpinnerModal.vue';
import AppButton from '@/components/AppButton.vue';
import AppButtonGroup from '@/components/buttongroup/AppButtonGroup.vue';
import AppDropdown from '@/components/dropdown/AppDropdown.vue';
import AppDropdownList from '@/components/dropdown/AppDropdownList.vue';
import AppLabel from '@/components/AppLabel.vue';
import Tabs from '@/components/tabber/Tabs.vue';
import NewMessage from '@/components/settings/inbox/inbox-component/NewMessage.vue';
import ContactNameIcon from '@/components/contacts/ContactNameIcon.vue';
import { getTimelineDropDownList } from '@/api/TimelineServices';
import ContactBoardHeader from '@/components/header/contact/ContactBoardHeader.vue';
import PropertyBoardHeader from '@/components/header/property/PropertyBoardHeader.vue';
import { useChatStore } from '@/stores/chatStore';
import { getModPermissionsData } from '@/api/UserServices';

// Spinner Loader import
import { SpinnerLoader } from '@/composables/SpinnerLoader';
const ChatStore = useChatStore();
const { spinnerModal, LoaderContent } = SpinnerLoader();
// Composables imports
import {
    getUserImage,
    getUserFullName,
    getPreviousNextRoute,
    useScreenSize,
} from '@/composables/globals';

// API services imports
import Csrf from '@/api/Csrf';

//Board store imports
import { useBoardStore } from '@/stores/boardStore';

const boardStore = useBoardStore();
const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const tenantStore = useTenantStore();
const headerStore = useHeaderStore();
const isDesktop = useScreenSize();
const toggleName = ref();
const headerProps = ref({});

onBeforeMount(() => {
    if (tenantStore && tenantStore.tenant.appearance === 'dark') {
        document.documentElement.classList.add('dark');
    }

    if (tenantStore && tenantStore.tenant.lang) {
        locale.value = tenantStore.tenant.lang === 'en-GB' ? 'en' : 'fr';
    }
});

onMounted(async () => {
    // emitter.on('diaryEventDropdownList', fetch_dorpdown_list);
    if (Object.keys(authStore.authUser).length !== 0) {
        Csrf.getCookie();
        getPreviousNextRoute();
        // fetch_dorpdown_list();
        await router.isReady();
        toggleName.value = currentRouteName.value;
        headerStore.setBoardCount(0);
        headerStore.setSelectedItemStatus(false);
    }
});

const pageHeading = computed(() => {
    const routeName = router.currentRoute.value.name;
    headerStore.setHeaderRouteName(routeName);
    switch (route.name) {
        case 'dashboard':
            return 'Dashboard';
        case 'Inbox':
            return 'Inbox';
        case 'timeline':
            return 'Timeline';
        case 'diary-page':
            return 'Diary';
        case 'contact-boards':
            return 'Contact Boards';
        case 'property-boards':
            return 'Property Boards';
        case 'client-account':
            return 'Accounts';
        case 'deposit-account':
            return 'Accounts';
        case 'deposit-custodial':
            return 'Accounts';
        case 'money-in':
            return 'Accounts';
        case 'money-out':
            return 'Accounts';
        case 'floats':
            return 'Accounts';
        default:
            return 'Page name';
    }
});

watch(pageHeading, () => {
    boardStore.setCurrentBoard(pageHeading.value);
});

const updateHeaderProps = (props) => {
    headerProps.value = props;
};

const getClientPortalUrl = computed(() => {
    return tenantStore && tenantStore.getTenant?.client_portal_url;
});

const currentRouteName = computed(() => {
    return router.currentRoute.value.name;
});

const changeView = (value) => {
    toggleName.value = value.name;
    if (value.name === 'conversation') {
        router.push('conversation');
        toggleName.value = 'conversation';
    } else {
        router.push('timeline');
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: 'Inter', sans-serif;
    @apply transition-all ease-linear relative;
    -webkit-overflow-scrolling: touch;
}

body {
    line-height: 1.5;
}
/* Basic styling to arrange the layout */
.main-layout {
    display: flex;
}

.side-header {
    width: 200px;
}

/* Main Container */
.app_container {
    @apply w-full bg-gray-100 dark:bg-black-900 !h-screen  pb-[5rem] sm:pb-0;
}

@media only screen and (max-width: 1270px) {
    .pad_view_show {
        @apply !block;
    }
    .pad_view_flex {
        @apply flex;
    }
    .pad_view_hidden {
        @apply hidden;
    }
}

@media only screen and (max-width: 574px) {
    .mobile_view_flex {
        @apply !flex;
    }
    .modile_view_hide {
        @apply hidden;
    }
    .modile_view_hidden {
        @apply hidden;
    }
}
</style>
