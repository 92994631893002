import { reactive } from 'vue';

export const contactsList = reactive({
    contacts: [],
});

export const selectedContact = reactive({
    contactId: null,
});

export const agentDetails = reactive({
    agentId: null,
});
