export default [
    {
        path: '/setting',
        name: 'setting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/settings/Setting.vue'),
        meta: { isWorkspace: true },
        children: [
            {
                path: 'general',
                name: 'general-settings',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/General.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'branches',
                name: 'branches',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/Branches.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'user-groups',
                name: 'user-groups',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/UserGroups.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'users',
                name: 'users',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/Users.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'permission',
                name: 'permissions',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/Permission.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'properties-categories',
                name: 'properties-categories',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/properties/PropertyCategories.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'property-data-fields',
                name: 'property-data-fields',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/properties/PropertyDataFieldsView.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'property-sale-data-fields',
                name: 'property-sale-data-fields',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/properties/PropertySaleDataFieldsView.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'tenancy-data-fields',
                name: 'tenancy-data-fields',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/properties/TenancyDataFieldsView.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'contacts-data-fields',
                name: 'contacts-data-fields',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/contacts/ContactsDataFieldsView.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'contacts-imports',
                name: 'contacts-imports',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/contacts/ContactsImports.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'event-types',
                name: 'event-types',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/diary/EventTypes.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'custom-bots',
                name: 'custom-bots',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/chatmessenger/CustomChatBot.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'customize-widget',
                name: 'customize-widget',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/branding/CustomizeWidget.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'integration',
                name: 'integration',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/chatmessenger/MessengerIntegration.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'data-capture',
                name: 'data-capture',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/chatmessenger/CustomChatBot.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'property-portals',
                name: 'property-portals',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/portals/PropertyPortal.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'email-design',
                name: 'email-design',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/branding/EmailDesign.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'letter-head',
                name: 'letter-head',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/branding/LetterHead.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'canned-responses',
                name: 'canned-responses',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/templates/CannedResponses.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'documents',
                name: 'documents',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/templates/Documents.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'checklists',
                name: 'checklists',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('../../views/settings/templates/Checklists.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'sales-checklist',
                name: 'sales-checklist',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('../../views/settings/templates/SalesChecklist.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'system-templates',
                name: 'system-templates',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('../../views/settings/templates/SystemTemplates.vue'),
                meta: { isWorkspace: true },
            },
            
            {
                path: 'print-property',
                name: 'print-property',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('../../views/settings/templates/PrintProperty.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'processing-screen',
                name: 'processing-screen',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('../../views/settings/ProcessingScreen/processingScreen.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'accounts-general',
                name: 'accounts-general',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/accounts/General.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'fee-types',
                name: 'fee-types',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/accounts/FeeTypes.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'upload-data',
                name: 'upload-data',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/settings/data-import/upload.vue'),
                meta: { isWorkspace: true },
            },
           
        ],
    },
];
